import React, { ReactNode } from 'react';
import styled from 'styled-components';

import { Header } from './Header';
import { Footer } from './Footer';

type Props = {
  children: ReactNode;
  path?: string;
};

export function Layout({ children, path }: Props) {
  return (
    <>
      <Header path={path} />
      <Main>{children}</Main>
      <Footer />
    </>
  );
}

const Main = styled.main`
  padding-top: 50px;
`;
