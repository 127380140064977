import { graphql, useStaticQuery } from 'gatsby';
import React, { ReactNode } from 'react';
import styled from 'styled-components';

import Icon, { IconContainer, Icons } from '../icons/Icon';
import CV from '../media/Darren_Labithiotis_CV.pdf';

type ProfileData = {
  email: string;
  github: string;
  linkedin: string;
  twitter: string;
};

type Data = {
  contentfulPerson: ProfileData;
};

export function Links() {
  const data: Data = useStaticQuery(graphql`
    query {
      contentfulPerson(name: { eq: "Darren Labithiotis" }) {
        email
        github
        linkedin
        twitter
      }
    }
  `);

  return (
    <Container>
      <LinkItem icon={Icons.file} tooltip="CV" target="_self" href={CV} delay={0.5} />
      <LinkItem icon={Icons.linkedIn} tooltip="LinkedIn" href={data.contentfulPerson.linkedin} delay={1} />
      <LinkItem icon={Icons.github} tooltip="Github" href={data.contentfulPerson.github} delay={1.5} />
      <LinkItem icon={Icons.mail} tooltip="Email" href={`mailto:${data.contentfulPerson.email}`} delay={2} />
    </Container>
  );
}

type LinkItemProps = {
  icon: Icons;
  tooltip: ReactNode;
  href: string;
  delay: number;
  target?: string;
};

function LinkItem({ icon, tooltip, href, target = '_blank', delay }: LinkItemProps) {
  return (
    <LinkContainer delay={delay}>
      <Link href={href} target={target}>
        <LinkLabel>
          <Icon icon={icon} size={16} />
        </LinkLabel>
        <LinkTooltipContainer>
          <LinkTooltip>{tooltip}</LinkTooltip>
        </LinkTooltipContainer>
      </Link>
    </LinkContainer>
  );
}

const Container = styled.ul`
  position: fixed;
  top: 0;
  right: 50px;
  list-style-type: none;
  pointer-events: auto;
  z-index: 10;
`;

const LinkTooltipContainer = styled.div`
  position: absolute;
  left: 20px;
  top: 100%;
  margin-left: -100px;
  width: 200px;
  display: flex;
  justify-content: center;
  margin-top: 80px;
  opacity: 0;
  visibility: hidden;
  transition: margin-top 0.3s ease, opacity 0.3s ease;
`;

const LinkContainer = styled.li<{ delay: number }>`
  position: relative;
  float: left;
  width: 40px;
  height: 50px;
  margin-top: -60px;
  margin-right: 10px;
  background-color: rgba(0, 0, 0, 0.2);
  cursor: pointer;
  border: 1px solid transparent;
  border-radius: 5px;
  transition: transform 0.2s, background-color 0.4s ease, border 0.3s ease;
  animation: linkSlideIn 1s ease forwards ${({ delay }) => `${delay}s`};

  @keyframes linkSlideIn {
    0% {
      margin-top: -60px;
    }
    100% {
      margin-top: -15px;
    }
  }

  :hover {
    transform: translateY(10px);
    background-color: rgba(0, 0, 0, 0.6);
    border-color: var(--color-primary);

    ${LinkTooltipContainer} {
      visibility: visible;
      opacity: 1;
      margin-top: 16px;
    }
  }
`;

const Link = styled.a`
  position: absolute;
  width: 100%;
  height: 100%;
`;

const LinkLabel = styled.span`
  text-decoration: none;
  color: white;
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  padding-bottom: 5px;

  ${IconContainer} {
    display: inline-block;
  }
`;

const LinkTooltip = styled.div`
  padding: 2px 10px;
  border-radius: 5px;
  background-color: var(--color-primary);
  color: #fff;
  font-size: 14px;

  &:after {
    position: absolute;
    top: -20px;
    left: 50%;
    margin-left: -10px;
    content: '';
    height: 0;
    width: 0;
    pointer-events: none;
    border: solid transparent; // need this
    border-color: rgba(0, 0, 0, 0);
    border-bottom-color: var(--color-primary);
    border-width: 10px;
    margin-left: -10px;
    backface-visibility: hidden;
    transition: opacity 0.3s ease;
  }
`;
