import React from 'react';
import styled from 'styled-components';

export function Footer() {
  const host = typeof window === `undefined` ? 'labithiotis.co.uk' : window.location.hostname;

  return (
    <Container>
      <div>
        <strong>Built by</strong> Darren Labithiotis
      </div>
      <div>
        2009-{new Date().getFullYear()} © <a href={`mailto:darren@${host}`}>{host}</a>
      </div>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  text-align: center;
  padding: 60px 0;
  color: var(--color-gray500);

  strong {
    font-weight: 500;
    color: var(--color-gray300);
  }

  a {
    text-decoration: none;
    color: var(--color-gray300);

    :hover {
      color: var(--color-gray700);
    }
  }
`;
