import { Link } from 'gatsby';
import React from 'react';
import styled from 'styled-components';

import Icon, { Icons } from '../icons/Icon';
import { Links } from './Links';
import { ThemeToggle } from './ThemeToggle';

type Props = {
  path?: string;
};

export function Header({ path }: Props) {
  return (
    <Container>
      {path && path !== '/' && (
        <Title to="/">
          <Icon size={20} icon={Icons.home} />
        </Title>
      )}
      {path && <Links />}
      <ThemeToggle />
    </Container>
  );
}

const Container = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  z-index: 30;
`;

const Title = styled(Link)`
  display: inline-block;
  text-decoration: none;
  margin-top: 2px;
  margin-left: 2px;
  background-color: var(--color-background);
  padding: 8px;
  border-radius: 50%;
`;
