import React from 'react';
import styled from 'styled-components';

import Icon, { IconContainer, Icons } from '../icons/Icon';
import { ColorMode, ThemeContext } from './ThemeContext';

export const ThemeToggle = () => {
  const { colorMode, setColorMode } = React.useContext(ThemeContext);
  const isDark = colorMode === ColorMode.dark;
  return (
    <Button
      tabIndex={-1}
      aria-label="dark-mode-toggle"
      onClick={() => setColorMode(isDark ? ColorMode.light : ColorMode.dark)}
    >
      <Icon size={20} icon={isDark ? Icons.sun : Icons.moon} />
    </Button>
  );
};

const Button = styled.button`
  padding: 8px 10px;
  cursor: pointer;
  position: fixed;
  right: 10px;
  top: 0;
  z-index: 10;
  opacity: 0;
  animation: toggleFadeIn 1s ease forwards 2.5s;

  @keyframes toggleFadeIn {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    100% {
      opacity: 1;
      transform: rotate(0deg);
    }
  }

  background-color: transparent;
  border: none;
  outline: none;
  color: var(--color-text);

  ${IconContainer} {
    transition: transform 0.4s ease-in;
  }

  :hover {
    ${IconContainer} {
      transform: scale(1.2);
    }
  }

  :active {
    ${IconContainer} {
      transform: scale(0.6);
    }
  }
`;
