import React, { Component, FunctionComponent, MouseEventHandler, SVGProps } from 'react';
import styled from 'styled-components';

export enum Icons {
  file = 'file',
  github = 'github',
  linkedIn = 'linked_in',
  mail = 'mail',
  moon = 'moon',
  sun = 'sun',
  heart = 'heart',
  home = 'home',
}

type SVG = FunctionComponent<SVGProps<SVGSVGElement>>;

type IconSVGs = { [icon in Icons]: SVG };

type Props = {
  icon: Icons;
  size?: number;
  color?: string;
  className?: string;
  onClick?: MouseEventHandler<HTMLElement>;
  active?: boolean;
};

type ContainerProps = {
  size: number;
  color: string;
  ratio: number;
};

export default class Icon extends Component<Props> {
  static defaultProps: Partial<Props> = {
    size: 32,
    color: 'currentColor',
  };

  calculateRatio(svg: SVG) {
    const viewBox = svg?.defaultProps?.viewBox;

    if (viewBox) {
      const [width, height] = viewBox.split(' ').slice(2);
      return parseInt(height) / parseInt(width);
    }

    return 1;
  }

  render() {
    const { icon, size, color, className, onClick } = this.props;
    const SVGIcon = SVGIcons[icon];

    if (!SVGIcon) {
      console.warn(`Can not find icon "${icon}"`);
      return null;
    }

    return (
      <IconContainer className={className} onClick={onClick}>
        <Container size={size!} color={color!} ratio={this.calculateRatio(SVGIcon)}>
          <SVGIcon {...{ size, color }} />
        </Container>
      </IconContainer>
    );
  }
}

export const IconContainer = styled.div``;

const Container = styled.i<ContainerProps>`
  width: ${(props) => props.size}px;
  color: ${(props) => props.color};
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 100%;
    height: ${(props) => props.size * props.ratio}px;
    overflow: hidden; //deal with an IE11 bug: https://stackoverflow.com/questions/44827875/issues-with-whitespace-around-svg-in-ie11-related-to-text-in-the-svg?utm_medium=organic&utm_source=google_rich_qa&utm_campaign=google_rich_qa
  }
`;

const SVGIcons = Object.values(Icons).reduce((acc: IconSVGs, file) => {
  acc[file] = require(`./svgs/${file}.svg`);
  return acc;
}, {} as IconSVGs);
