import React from 'react';
import styled from 'styled-components';

export const Container = styled.div`
  margin: 0 auto;
  max-width: 960px;
  padding: 0 1.0875rem 1.45rem;

  @media only screen and (max-width: 800px) {
    padding-left: 20px;
    padding-right: 20px;
  }
`;
